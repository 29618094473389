<!-- Modal -->
<div #loginModal id="loginModal" class="modal loginModal fade in" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 imageContainer">
          <div
            class="coverImage"
            [ngStyle]="{
              background:
                'url(' +
                'assets/images/hospitallogin.jpg' +
                ') no-repeat center center local',
              'background-size': 'cover',
              'z-index': '1'
            }"
          >
            <button type="button" class="close" data-dismiss="modal">
              <i aria-hidden="true" class="fa fa-times"></i>
            </button>
            <div class="row">
              <div class="col-md-9 offset-md-2 text-center loginformView">
                <!-- <span style="border-radius: 50%; background-color: cornflowerblue; 
                font-size: xx-large; border: 2px  solid #000; padding: 10px;">
                  <i class="fa fa-sign-in"></i>
                </span><br><br> -->

                <div class="row mt-2">
                  <div class="col-md-12">
                    <span style="font-size: xx-large; color: black"
                      >Log-In</span
                    >
                  </div>
                </div>
                <br />

                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="submitForm(loginForm.value)"
                  class="login-form"
                >
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <div
                        *ngIf="hasHttpResponseError"
                        style="color: red"
                        class="error-message-text mt-2 mb-2"
                      >
                        {{ this.httpResponseError }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls['username'].valid &&
                        loginForm.controls['username'].dirty
                    }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      [formControl]="loginForm.controls['username']"
                      placeholder="Username"
                      style="border: 1px solid #06060c; border-radius: 20px"
                    />
                  </div>

                  <div
                    class="form-group"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls['Password'].valid &&
                        loginForm.controls['Password'].dirty
                    }"
                  >
                    <input
                      type="password"
                      class="form-control"
                      id="pwd"
                      [formControl]="loginForm.controls['Password']"
                      placeholder="Password"
                      style="border: 1px solid #06060c; border-radius: 20px"
                    />
                  </div>

                  <div
                    *ngIf="userWiseHospitalList && userWiseHospitalList.length"
                    class="form-group"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls['HospitalGID'].valid &&
                        loginForm.controls['HospitalGID'].dirty
                    }"
                  >
                    <select
                      class="form-control"
                      [formControl]="loginForm.controls['HospitalGID']"
                      (change)="onChangeHospital()"
                      style="border: 1px solid #06060c; border-radius: 20px"
                    >
                      <option value="">Select Hospital</option>
                      <option
                        *ngFor="let item of userWiseHospitalList"
                        value="{{ item.HospitalGID }}"
                      >
                        {{ item.HospitalName }}
                      </option>
                    </select>
                  </div>

                  <div
                    *ngIf="
                      loginForm.controls['HospitalGID'].value &&
                      userWiseBranchList &&
                      userWiseBranchList.length
                    "
                    class="form-group"
                    [ngClass]="{
                      'has-error':
                        !loginForm.controls['branchID'].valid &&
                        loginForm.controls['branchID'].dirty
                    }"
                  >
                    <select
                      class="form-control"
                      [formControl]="loginForm.controls['branchID']"
                      style="border: 1px solid #06060c; border-radius: 20px"
                    >
                      <option value="">Select Branch</option>
                      <option
                        *ngFor="let item of userWiseBranchList"
                        value="{{ item.BranchIDCode }}"
                      >
                        {{ item.LocationName }}
                      </option>
                    </select>
                  </div>

                  <div class="row">
                    <div class="col-md-6 text-left">
                      <span>
                        <a href="/forget-password" style="color: black">
                          Forgot password ?
                        </a></span
                      >
                    </div>
                    <div class="col-md-6 text-left">
                      <a
                        data-toggle="modal"
                        data-target="#registerModal"
                        data-dismiss="modal"
                        style="color: black"
                      >
                        I dont have account | Signup
                      </a>
                    </div>
                  </div>

                  <br />
                  <div class="form-group has-feedback">
                    <button
                      type="submit"
                      style="border-radius: 20px"
                      class="btn btn-primary btn-block"
                      [disabled]="!loginForm.valid || !submitButtonStatus"
                    >
                      <span *ngIf="submitButtonStatus"> Login </span>
                      <span *ngIf="!submitButtonStatus">
                        Verifying ...
                        <i class="fa fa-spinner fa-pulse fa-fw"> </i>
                      </span>
                    </button>
                  </div>
                </form>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <span class="or-content">
                      <span style="font-size: 18px; color: black">
                        <i>-OR-</i>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center" style="color: black">
                    <span
                      (click)="signIn('facebook')"
                      class="fab fa-facebook"
                      aria-hidden="true"
                      style="font-size: x-large"
                    ></span
                    >&nbsp;
                    <span
                      (click)="signIn('facebook')"
                      class="fab fa-google"
                      aria-hidden="true"
                      style="font-size: x-large"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
