import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { RegisterService } from "../../../services/register.service";
import { LoginService } from "../login/login.service";
import { HttpEvent } from "@angular/common/http";
import { HttpEventType } from "@angular/common/http";
import { Router } from '@angular/router';
import { iDoctorNewCreateLogin } from 'src/app/interfaces/iDoctorNewCreateLogin';
import { AppEnvironemnt } from 'src/app/app.environment';
import { MasterRepo } from "src/app/services/masterRepo.service";
import { iPatientNewCreateLogin } from 'src/app/interfaces/iPatientNewCreateLogin';


declare var jQuery: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['register.component.scss']
})
export class RegisterComponent implements OnInit {
    public sub: any;
    public useUsernamePassword = false;
    public submitButtonStatus: boolean = true;
    public countryList: any[];

    email = new FormControl('', [
        Validators.required,
    ]);

    password = new FormControl('', [Validators.required]);
    registerForm: FormGroup = this.builder.group({
        email: this.email,
        password: this.password
    });

    public invalidEmail: any;

    constructor(public builder: FormBuilder,
        private router: Router,
        public registerService: RegisterService,
        public postLoginService: LoginService,
        private masterService: MasterRepo,
        public http: HttpClient) {
        this.getCountryList();
    }
    date: any;
    ngOnInit() {
        this.date = new Date().toISOString().slice(0, 10);

    }

    public validateEmail() {
        // this.registerService.validateEmail({ 'email': this.email.value })
        //     .subscribe(
        //         (event: HttpEvent<any>) => {
        //             switch (event.type) {
        //                 case HttpEventType.Response:
        //                     let invalidEmail = event.body
        //                     if (invalidEmail.email) {
        //                         this.invalidEmail = invalidEmail.email;
        //                     }
        //             }
        //         },
        //         error => {
        //         });
    }




    signIn(provider: string) {

    }

    public submitForm(formData: any): void {
        this.submitButtonStatus = false;


    }

    public register(formData: any): void {
        this.submitButtonStatus = false;
        if (this.registerForm.valid) {
            this.registerService.register(formData)
                .subscribe(
                    response => {
                        if (response.user) {
                            this.submitButtonStatus = true;
                            this.registerForm.reset()
                            jQuery("#registerModal").modal("hide");
                            jQuery("#checkMailVerification").modal("show");
                        }
                    }, error => {
                    }
                )
        }
    }


    registerAsHospital() {
        jQuery("#registerModal").modal("hide");
        this.router.navigate(['./register']);
    }
    public doctorForm: iDoctorNewCreateLogin = <iDoctorNewCreateLogin>{

    };


    OnSaveDoctorData(Formdata) {
        let datatosubmit = {
            mode: 'NEW',
            data: Formdata
        }
        this.http.post(AppEnvironemnt.apiServer + '/createDoctorNewLoginsInfo', datatosubmit).subscribe((result: any) => {
            console.warn(result)
        });
        console.warn(Formdata)
    }
    numericOnly(event): boolean {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }
    alphaOnly(event): boolean {
        let patt = /^([ a-zA-Z])$/;
        let result = patt.test(event.key);
        return result;
    }
    alphaNumberOnly(event): boolean {
        let patt = /^([a-zA-Z0-9-_ ])$/;
        let result = patt.test(event.key);
        return result;
    }
    getCountryList() {
        this.masterService.getCountryListForAnonymusUser().subscribe((res: any) => {
            this.countryList = res.result;

        });
    }
    public patientForm: iPatientNewCreateLogin = <iPatientNewCreateLogin>{
    
    };
    OnSavePatientData(Formdata) {
        let datatosubmit = {
          mode:'NEW',
          data:Formdata
        }
        this.http.post(AppEnvironemnt.apiServer + '/createPatientNewLoginsInfo',datatosubmit).subscribe((result: any) => {
          console.warn(result)
        });
        console.warn(Formdata)
      }

}
