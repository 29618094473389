import {Injectable, Inject, ComponentFactoryResolver} from '@angular/core';
import {LoginComponent} from "../login/login.component";
import {RegisterComponent} from "../register/register.component";

@Injectable()
export class LoginRegisterService {

  factoryResolver;
  rootViewContainer:any;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver
  }
  setRootViewContainerRef(viewContainerRef: any) {
    this.rootViewContainer = viewContainerRef
  }
  addDynamicComponent() {
    if(typeof document != "undefined") {
      const factory1 = this.factoryResolver
          .resolveComponentFactory(LoginComponent)
      const component1 = factory1
          .create(this.rootViewContainer.parentInjector)
      this.rootViewContainer.insert(component1.hostView)

      const factory2 = this.factoryResolver
          .resolveComponentFactory(RegisterComponent)
      const component2 = factory2
          .create(this.rootViewContainer.parentInjector)
      this.rootViewContainer.insert(component2.hostView)

    }
  }
}