<div id="registerModal" class="modal registerModal fade in" role="dialog">
  <div class="modal-dialog login-modal">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-6 imageContainer">
          <div class="coverImage"
            [ngStyle]="{'background': 'url('+'/assets/images/sideimg.jpg'+') no-repeat center center local','background-size':'cover'}">
          </div>
        </div>
        <div class="col-md-6 registerContainer">
          <div class="modal-body modal-body-ecn">
            <p class="login-box-msg text-center ">
              Connect with best hospitals near you.
              Book your apointments
              easily!
            </p>

            <div class="social-auth-login">
              <a class="btn btn-block btn-social
                            btn-facebook btn-flat" (click)="signIn('facebook')">
                <i class="fa fa-facebook" aria-hidden="true"></i>
                <span>Continue with Facebook</span>
              </a>
              <a class="btn btn-block btn-social
                            btn-google btn-flat" (click)="signIn('google')">
                <i class="fa fa-google" aria-hidden="true"></i>
                <span>Continue with Gmail</span>

              </a>
              <p class="info-dic-content">
                we will never post without your permission
              </p>
              <div class="or-container">
                <span class="or-content">
                  <span class="or-text">
                    <i> - &nbsp; or &nbsp; - </i>
                  </span>
                </span>
              </div>
            </div>
            <!-- <form [formGroup]="registerForm" (ngSubmit)="register(registerForm.value)" class="register-form"
              autocomplete="false">
              <div class="form-group" [ngClass]="{'has-error':email.invalid && email.dirty}">

                <input type="email" class="form-control" (change)="validateEmail()" [formControl]="email" email
                  placeholder="E-mail" autocomplete="false">

                <span *ngIf="email.dirty && email.invalid">
                  <ul class="error">
                    <li></li>
                    <li *ngIf="email.errors.required">
                      Please insert the email
                    </li>
                    <li *ngIf="email.errors.duplicateEmail">
                      This email has already been registered
                    </li>
                    <li *ngIf="email.errors.email">
                      Invalid email format
                    </li>
                  </ul>
                </span>

              </div>

              <div class="form-group has-feedback" [ngClass]="{'has-error':password.invalid &&
                                 password.dirty}">
                <input type="password" class="form-control" [formControl]="password"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$" placeholder="Password">
                <span *ngIf="password.dirty && password.invalid">
                  <ul class="error">
                    <li *ngIf="password.errors.required">
                      Please insert the password
                    </li>
                    <li *ngIf="password.errors.pattern">
                      Please input 6 character alpha-numeric password
                    </li>
                  </ul>
                </span>


              </div>

              <div class="form-group has-feedback">
                <button type="submit" class="btn btn-default btn-block" [disabled]="!registerForm.valid ||
                                        !submitButtonStatus">
                  <span *ngIf="submitButtonStatus">
                    Sign up
                  </span>
                  <span *ngIf="!submitButtonStatus">
                    Verifying ...
                    <i class="fa fa-spinner
                                        fa-pulse  fa-fw"></i>
                  </span>
                </button>
                <div class="clearfix"></div>
              </div>
            </form> -->
            <div class="register-doctor register-patient">
              <button type="button" class="btn btn-block btn-username-password btn-flat" data-toggle="modal"
                data-target="#registerpatient">
                <i class="far fa-user" aria-hidden="true">
                </i> Register as patient
              </button>
            </div>
            <div class="register-doctor">
              <button type="button" class="btn btn-block btn-username-password btn-flat" data-toggle="modal"
                data-target="#registerdoctor">
                <i class="far fa-stethoscope" aria-hidden="true">
                </i> Register as Doctor
              </button>
            </div>
            <div class="register-hospital">
              <a class="btn btn-block btn-username-password btn-flat" (click)="registerAsHospital()">
                <i class="far fa-hospital" aria-hidden="true">
                </i>
                <span>
                  Register as Hospital
                </span>
              </a>
            </div>


            <div class="content-term-condition">
              <p>
                By signing up, you agree to Eclat Info Sys
                <br>
                <!-- <a href="#" id="terms-and-condition" > -->
                <a href='/terms-and-condition' class="anchor-css">Terms &amp; condition </a>

                <!-- </a> -->
                and
                <a href='/privacy-policy' class="anchor-css">
                  Privacy Policy
                </a>
              </p>
            </div>
            <div class="login-box-msg">
              <a data-toggle="modal" data-target="#loginModal" data-dismiss="modal">
                I already have an account | login
              </a>
            </div>

          </div>

          <button type="button" class="close" data-dismiss="modal">
            <i aria-hidden="true" class="fa fa-times"></i>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal for doctor -->
<div class="modal fade" id="registerdoctor" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content p-4">
      <div class="modal-header">
        <h3 class="modal-title">Doctor Registration</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #doctorForm="ngForm" (ngSubmit)="OnSaveDoctorData(doctorForm.value)">
        <div class="row">
          <div class="col-md-6">
            <label for="firstName" class="form-label mb-0">First Name<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
              </div>
            <input type="text" class="form-control" name="firstName" #firstName="ngModel"
              [(ngModel)]="doctorForm.firstName" required (keypress)="alphaOnly($event)">
            <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="form-alert text-danger">
              <div class="text-danger" *ngIf="firstName.errors['required']">Please Enter Firstname</div>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="lastName" class="form-label mb-0">lastName<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
              </div>
            <input type="text" class="form-control" name="lastName" #lastName="ngModel"
              [(ngModel)]="doctorForm.lastName" required (keypress)="alphaOnly($event)">
            <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
              <div class="text-danger" *ngIf="lastName.errors['required']">Please Enter lastName</div>
            </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-6">
            <label for="phoneNumber" class="form-label mb-0">phoneNumber<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-phone"></i>
                </span>
              </div>
            <input type="text" class="form-control" name="phoneNumber" #phoneNumber="ngModel"
              onkeypress='return event.charCode >= 48 && event.charCode <= 57' minlength="10" maxlength="10"
              [(ngModel)]="doctorForm.phoneNumber" placeholder="phoneNumber">

            <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="text-danger">
              <div class="text-danger" *ngIf="phoneNumber.errors['required']">Please Enter PhoneNumber No.</div>
              <div class="text-danger" *ngIf="phoneNumber.errors['minlength']">PhoneNumber. must be at 10
                numbers.
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="dateOfBirth" class="form-label mb-0">Date Of Birth<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </span>
              </div>
            <input type="date" class="form-control" name="dateOfBirth" #dateOfBirth="ngModel"
              [(ngModel)]="doctorForm.dateOfBirth" required max="{{this.date}}">
            <div *ngIf="dateOfBirth.invalid && (dateOfBirth.dirty || dateOfBirth.touched)" class="text-danger">
              <div class="text-danger" *ngIf="dateOfBirth.errors['required']">Please Enter Date of birth</div>
            </div>
          </div>
          </div>
        </div>

         <div class="row mt-1">
          <div class="col-md-6">
            <label for="email" class="form-label mb-0">Email Address <span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="doctorForm.email"
              placeholder="email Address" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
              <div class="text-danger" *ngIf="email.errors['required']">Please Enter email Address</div>
              <div class="text-danger" *ngIf="email.errors['pattern']">Invalid email Address</div>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="country" class="form-label mb-0">Country<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-flag"></i>
                </span>
              </div>
            <select type="text" class="form-control" name="country" placeholder="Country" #country="ngModel"
              [(ngModel)]="doctorForm.country">
              <option value="" selected disabled>Choose Country</option>
              <option *ngFor="let item of countryList" value={{item.CountryName}}>{{item.CountryName}}</option>
            </select>
            <div *ngIf="country.invalid && (country.dirty || country.touched)" class="text-danger">
              <div class="text-danger" *ngIf="country.errors['required']">country pls</div>
              country is required.
            </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-6">
            <label for="gender" class="form-label mb-0">Gender<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-female"></i>
                </span>
              </div>
            <select name="gender" class="form-control" #gender="ngModel" [(ngModel)]="doctorForm.gender">
              <option>Male</option>
              <option>Female</option>
            </select>
            <div *ngIf="gender.invalid && (gender.dirty || gender.touched)" class="text-danger">
              <div class="text-danger" *ngIf="gender.errors['required']">gender pls</div>
              gender is required.
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="userName" class="mb-0">Username<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user-circle"></i>
                </span>
              </div>
            <input type="text" class="form-control" #username="ngModel" name="username"
              [(ngModel)]="doctorForm.username" placeholder="asdsad" required autocomplete="off"
              (keypress)="alphaNumberOnly($event)">
            <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
              <div *ngIf="username.errors['required']">
                username is required
              </div>
            </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-6">
            <label for="password" class="mb-0">Password<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-key"></i>
                </span>
              </div>
            <input type="password" class="form-control" #password="ngModel" name="password"
              [(ngModel)]="doctorForm.password" placeholder="********" required autocomplete="off">
            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
              <div *ngIf="password.errors['required']">
                Password is required
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="confirmPassword" class="mb-0">Confirm Password <span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-check-circle"></i>
                </span>
              </div>
            <input type="password" class="form-control" #confirmPassword="ngModel" name="confirmPassword"
              [(ngModel)]="doctorForm.confirmPassword" placeholder="********" required>
            <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
              class="text-danger">
              <div *ngIf="confirmPassword.errors['required']">
                Password is required
              </div>
            </div>
            </div>
          </div>
        </div>

          <div class="container text-center mt-4">
              <button type="submit" class="btn btn-custom" name="register" (click)="OnSaveDoctorData">Register</button>
          </div>
      </form>
    </div>
  </div>
</div>




<!-- patient register  -->
<div id="registerpatient" class="modal registerpatient fade in" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content p-4">
      <div class="modal-header">
        <h3 class="modal-title">Patient registration</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <form #patientForm="ngForm" (ngSubmit)="OnSavePatientData(patientForm.value)">
        <div class="row mt-2">
          <div class="col-md-6">
            <label for="firstName" class="form-label mb-0">First Name<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
              </div>
              <input type="text" class="form-control" name="firstName" #firstName="ngModel"
                [(ngModel)]="patientForm.firstName" required (keypress)="alphaOnly($event)">
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="form-alert text-danger">
                <div class="text-danger" *ngIf="firstName.errors['required']">Please Enter Firstname</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="lastName" class="form-label mb-0">lastName<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
              </div>
              <input type="text" class="form-control" name="lastName" #lastName="ngModel"
                [(ngModel)]="patientForm.lastName" required (keypress)="alphaOnly($event)">
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                <div class="text-danger" *ngIf="lastName.errors['required']">Please Enter lastName</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-6">
            <label for="phoneNumber" class="form-label mb-0">PhoneNumber<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-phone"></i>
                </span>
              </div>
              <input type="text" class="form-control" name="phoneNumber" #phoneNumber="ngModel"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57' minlength="10" maxlength="10"
                [(ngModel)]="patientForm.phoneNumber" placeholder="phoneNumber">
              <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="text-danger">
                <div class="text-danger" *ngIf="phoneNumber.errors['required']">Please Enter PhoneNumber No.</div>
                <div class="text-danger" *ngIf="phoneNumber.errors['minlength']">PhoneNumber. must be at 10
                  numbers.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="dateOfBirth" class="form-label mb-0">Date Of Birth<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </span>
              </div>
              <input type="date" class="form-control" name="dateOfBirth" #dateOfBirth="ngModel"
                [(ngModel)]="patientForm.dateOfBirth" required max="{{this.date}}">
              <div *ngIf="dateOfBirth.invalid && (dateOfBirth.dirty || dateOfBirth.touched)" class="text-danger">
                <div class="text-danger" *ngIf="dateOfBirth.errors['required']">Please Enter Date of birth</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-6">
            <label for="email" class="form-label mb-0">Email Address <span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
              <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="patientForm.email"
                placeholder="email Address" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                <div class="text-danger" *ngIf="email.errors['required']">Please Enter email Address</div>
                <div class="text-danger" *ngIf="email.errors['pattern']">Invalid email Address</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="country" class="form-label mb-0">Country<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-flag"></i>
                </span>
              </div>
              <select type="text" class="form-control" name="country" placeholder="Country" #country="ngModel"
                [(ngModel)]="patientForm.country">
                <option value="" selected disabled hidden>Choose Country</option>
                <option *ngFor="let item of countryList" value={{item.CountryName}}>{{item.CountryName}}</option>
              </select>
              <div *ngIf="country.invalid && (country.dirty || country.touched)" class="text-danger">
                <div class="text-danger" *ngIf="country.errors['required']">country pls</div>
                country is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-md-6">
            <label for="gender" class="form-label mb-0">Gender<span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-female"></i>
                </span>
              </div>
              <select name="gender" class="form-control" #gender="ngModel" [(ngModel)]="patientForm.gender">
                <option value="" selected disabled hidden>Gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>

              <div *ngIf="gender.invalid && (gender.dirty || gender.touched)" class="text-danger">
                <div class="text-danger" *ngIf="gender.errors['required']">gender pls</div>
                gender is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="userName" class="mb-0">Username<span class="text-danger mb-0">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user-circle"></i>
                </span>
              </div>
              <input type="text" class="form-control" #username="ngModel" name="username"
                [(ngModel)]="patientForm.username" placeholder="asdsad" required autocomplete="off"
                (keypress)="alphaNumberOnly($event)">
              <div *ngIf="username.invalid && (username.dirty || username.touched)" class="text-danger">
                <div *ngIf="username.errors['required']">
                  username is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-6">
            <label for="password" class="mb-0">Password<span class="text-danger mb-0">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-key"></i>
                </span>
              </div>
              <input type="password" class="form-control mt-0" #password="ngModel" name="password"
                [(ngModel)]="patientForm.password" placeholder="********" required autocomplete="off">
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                <div *ngIf="password.errors['required']">
                  Password is required
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="confirmPassword" class="mb-0">Confirm Password <span class="text-danger">*</span></label>
            <div class="form-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-check-circle"></i>
                </span>
              </div>
              <input type="password" class="form-control" #confirmPassword="ngModel" name="confirmPassword"
                [(ngModel)]="patientForm.confirmPassword" placeholder="********" required>
              <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
                class="text-danger">
                <div *ngIf="confirmPassword.errors['required']">
                  Password is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center mt-3">
          <button type="submit" class="btn btn-custom" (click)="OnSavePatientData">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>