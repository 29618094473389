import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginRegisterService } from "./login-register/login-register.service";
import { FormsModule, ReactiveFormsModule, NgForm, NgModel } from "@angular/forms";
import { LoginService } from "./login/login.service";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule],
    declarations: [LoginRegisterComponent, LoginComponent, RegisterComponent],
    exports: [
        LoginRegisterComponent
    ],
    entryComponents: [LoginComponent, RegisterComponent],
    providers: [
        LoginRegisterService,
        LoginService,
        NgForm,
        NgModel
    ]
})

export class LoginRegisterModule {
}


