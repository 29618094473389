<style>
    .lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(253, 253, 253);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.modal .modal-content {
    color: #f8f8f8;
}

.modal-content { 
    background: transparent !important;
    border: none !important; 
}

</style>
<div
  class="modal fade"
  bsModal
  #spinnerModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  style="z-index : 99999999;height: 100%;background: #11131375;"
>
  <div class="modal-dialog modal-sm" style="   max-width: 300px; position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);">
    <div class="modal-content"> 
      <div class="modal-body" style="float: center; text-align: center;">
         <div class="lds-ripple"><div></div><div></div></div>
        <p *ngIf="displayMessage">{{ displayMessage }}</p>
      </div> 
    </div>
  </div>
</div>
