import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppEnvironemnt } from '../app.environment';

@Injectable({providedIn:'root'})
export class RegisterService {
    public validateEmailUrl = "/validate-email";

    constructor(public http: HttpClient) {

    }


    public validateEmail(data: any): Observable<any> {

        const req = new HttpRequest('POST', AppEnvironemnt.apiServer + this.validateEmailUrl, data);
        return this.http.request(req)

    }

    public register(data: any): Observable<any> {
        return this.http.post(AppEnvironemnt.apiServer + '/member-register', data)
    }



}