import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AppEnvironemnt } from "src/app/app.environment";
import { LocalStorageService } from "src/app/helper_services/cache.service";


@Injectable()
export class LoginService {

  constructor(public http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router) {
  }

  private userLoginCredential: any;
  private usertype: any;

  login(data: any): Observable<any> {
    this.userLoginCredential = data;
    return this.http.post(AppEnvironemnt.apiServer +"/jwt", data)
  }

  socialLogin(data: any): Observable<any> {
    return this.http.post(AppEnvironemnt.apiServer + "/social-login", data)
  }


  checkUserType = (data: any) => {
    this.usertype = data;
    return this.http.post(AppEnvironemnt.apiServer +"/CheckUserType", data)
  }

  // HospitalGID/branchID
  getCredentialUsability() {
    return this.userLoginCredential;
  }

  getUserType(){
    return this.usertype;
  }

  logout() {
    this.localStorage.removeItem("token");  
    this.localStorage.removeItem("profile");  
    this.localStorage.removeItem("expires_in"); 
    this.router.navigate(['./Eclat/Log-in']);
  }

}
