import { Injectable, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppEnvironemnt } from '../app.environment';


@Injectable()
export class AppConfigService {

    appSetting: IAppConfig;

    constructor(public http: HttpClient, public injector: Injector) {
    }



    load() {
        const jsonfile = 'assets/appConfig.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonfile).toPromise().then((response: IAppConfig) => {
                this.appSetting = <IAppConfig>response;
                AppEnvironemnt.apiServer = this.appSetting['apiUrl'];
                AppEnvironemnt.imageServer = this.appSetting['imageServer'];
                AppEnvironemnt.version = this.appSetting['Version'];
                resolve();
            }).catch((response: any) => {
                reject('could not load file cofig file');
            })
        })
    }

}


export function AppConfigFactory(configService: AppConfigService) {
    return () => configService.load().then(() => {

    });
}



export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: AppConfigFactory,
        deps: [AppConfigService],
        multi: true
    }
}

const AppConfigModule = {
    init: init
}

export { AppConfigModule };

export interface IAppConfig {
    apiurl: string;
    Version: string;
    imageServer: string
}