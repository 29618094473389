import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { AppEnvironemnt } from 'src/app/app.environment';
import { LocalStorageService } from 'src/app/helper_services/cache.service';

declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('loginModal') loginModal: ElementRef;

  public useUsernamePassword = false;
  public hasHttpResponseError = false;
  public httpResponseError: string = null;

  public UserCredintial: any;

  public submitButtonStatus: boolean = true;

  public sub: any;
  public user: any;
  public imageServer: any;
  userWiseBranchList: any[] = [];
  userWiseHospitalList: any[] = [];

  public loginForm = this.fb.group({
    username: new FormControl(null, [Validators.required]),
    Password: new FormControl(null, [Validators.required]),
    HospitalGID: new FormControl('', [Validators.required]),
    branchID: new FormControl('', [Validators.required]),
  }) as FormGroup;

  constructor(
    private render: Renderer2,
    public fb: FormBuilder,
    public postLoginService: LoginService,
    public router: Router,
    private _localStorageService: LocalStorageService
  ) {
    this.imageServer = AppEnvironemnt.imageServer;
  }

  ngOnInit() {
    this.loginForm.controls['username'].valueChanges.subscribe((username) => {
      let password = this.loginForm.controls['Password'].value;
      if (username && password) {
        this.checkUserType();
      }
    });
    this.loginForm.controls['Password'].valueChanges.subscribe((password) => {
      let username = this.loginForm.controls['username'].value;
      if (username && password) {
        this.checkUserType();
      }
    });
  }

  checkUserType = () => {
    let data = {
      username: this.loginForm.controls['username'].value,
      Password: this.loginForm.controls['Password'].value,
    };
    this.postLoginService.checkUserType(data).subscribe(
      (res: any) => {
        this.userWiseBranchList = res.result.CompanyBranchDetails;
        this.userWiseHospitalList = res.result.HospitalList;
        this.hasHttpResponseError = false;
      },
      (error: any) => {
        if (error.error && error.error.username) {
          this.httpResponseError = error.error.username;
        } else if (error.status == 400) {
          this.httpResponseError = 'Invalid username or password ???.';
        } else {
          this.httpResponseError = 'An error occurred during login ???.';
        }
        this.hasHttpResponseError = true;
        if (!error.error || !error.error.username) {
          this.hasHttpResponseError = false;
        }
        this.hasHttpResponseError = true;
      }
    );
  };

  // Again we’ll implement our form submit function that will just console.log the results of our form
  public submitForm(formData: any): void {
    this.submitButtonStatus = false;
    this.postLoginService.login(formData).subscribe(
      (response: any) => {
        if (response.token) {
          // modal hide
          this.render.setStyle(
            this.loginModal.nativeElement,
            'display',
            'none'
          );
          const modalBackdrop = document.querySelector('.modal-backdrop');
          if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
          }
          this.loginForm.reset();
          this.submitButtonStatus = true;
          this._localStorageService.setItem('token', response.token);
          this._localStorageService.setItem('profile', response.profile);
          this._localStorageService.setItem('expires_in', response.expires_in);

          this.router.navigate(['./pages/']);
        }
      },
      (error: any) => {
        this.submitButtonStatus = true;
        this.hasHttpResponseError = true;
      }
    );
  }

  onChangeHospital() {
    if (this.loginForm.controls['HospitalGID'].value) {
      this.userWiseBranchList = this.userWiseBranchList.filter(
        (x) => x.HospitalGID == this.loginForm.controls['HospitalGID'].value
      );
    }
  }

  public submitSocialForm(formData: any): void {
    this.submitButtonStatus = false;
    this.postLoginService.socialLogin(formData).subscribe(
      (response) => {
        if (response.user) {
          this.render.setStyle(
            this.loginModal.nativeElement,
            'display',
            'none'
          );
          // jQuery("#loginModal").modal("hide")
          this.loginForm.reset();
          this.submitButtonStatus = true;
          location.reload();
        }
      },
      (error) => {
        this.submitButtonStatus = true;
        this.hasHttpResponseError = true;
        this.httpResponseError = 'credential didnt match.';
      }
    );
  }

  signIn(provider) {}

  logout() {}
  forgetPassword() {
    this.render.setStyle(this.loginModal.nativeElement, 'display', 'none');
    // jQuery("#loginModal").modal("hide")
    this.router.navigate['/forget-password'];
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
