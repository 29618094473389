import {Component, OnInit, Inject, ViewContainerRef} from '@angular/core';
import {LoginRegisterService} from "./login-register.service";

@Component({
  selector: 'app-login-register',
  template: ``,
  styleUrls: ['login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  constructor(@Inject(LoginRegisterService) service,
              @Inject(ViewContainerRef) viewContainerRef) {
    service.setRootViewContainerRef(viewContainerRef)
    service.addDynamicComponent()
  }

  ngOnInit() {
  }

}
